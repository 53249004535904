var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-header',{staticClass:"pb-1"},[(!_vm.profileValid)?_c('b-alert',{attrs:{"variant":"warning","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_c('b-link',{staticClass:"text-warning",attrs:{"to":{ name: 'update-profile' }}},[_c('strong',[_vm._v("Update your profile")])]),_vm._v(" to enable you to apply for this job. We need to check if you qualify for this post. "+_vm._s(_vm.profileError))],1)])]):_vm._e(),_c('b-media',{attrs:{"no-body":""}},[_c('b-media-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"1"}},[_c('feather-icon',{staticClass:"mr-50 mt-0 cursor-pointer text-primary",attrs:{"size":"30","icon":"CornerUpLeftIcon"},on:{"click":function($event){return _vm.$router.push({ name: 'jobs' })}}})],1),_c('b-col',{attrs:{"cols":"11"}},[_c('h4',{staticClass:"mb-0 ml-0"},[_c('b-badge',{attrs:{"pill":"","variant":"light-primary"}},[_vm._v(" "+_vm._s(_vm.job.number)+" ")])],1),_c('h4',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.job.post)+" "),_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":"light-secondary"}},[_vm._v(" "+_vm._s(_vm.job.positions)+" "),_c('small',[_vm._v("Position"+_vm._s(_vm.job.positions === 1 ? '' : 's'))])])],1),_c('small',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.bottom",value:(
              ("Job opened on " + (_vm.formatDate(_vm.job.opening_date, {
                month: 'short',
                day: 'numeric',
                weekday: 'short',
                year: 'numeric',
              })) + " closing on " + (_vm.formatDate(_vm.job.closing_date, {
                month: 'short',
                day: 'numeric',
                weekday: 'short',
                year: 'numeric',
              })))
            ),expression:"\n              `Job opened on ${formatDate(job.opening_date, {\n                month: 'short',\n                day: 'numeric',\n                weekday: 'short',\n                year: 'numeric',\n              })} closing on ${formatDate(job.closing_date, {\n                month: 'short',\n                day: 'numeric',\n                weekday: 'short',\n                year: 'numeric',\n              })}`\n            ",modifiers:{"hover":true,"bottom":true}}],staticClass:"text-muted",attrs:{"title":"Details"}},[_c('strong',[_vm._v(" "+_vm._s(_vm.jobRemaining ? _vm.jobClosed ? -_vm.timeRemaining(_vm.job.closing_date) : _vm.timeRemaining(_vm.job.closing_date) : _vm.timeRemaining(_vm.job.opening_date))+" day"+_vm._s((_vm.jobRemaining ? _vm.timeRemaining(_vm.job.closing_date) : _vm.timeRemaining(_vm.job.opening_date)) === 1 ? '' : 's')+" "+_vm._s(_vm.jobClosed ? 'elapsed' : 'remaining'))])])])],1)],1)],1),_c('b-badge',{attrs:{"pill":"","variant":"light-primary"}},[_vm._v(" "+_vm._s(_vm.department(_vm.job.department))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }