<template>
  <div>
    <b-card
      v-if="job"
      no-body
    >
      <job-header
        profile-error
        profile-valid
        :job="job"
        :job-remaining="jobRemaining"
        :job-closed="jobClosed"
        :time-remaining="timeRemaining"
        :find-department="findDepartment"
      />
      <b-card-body class="align-items-center">
        <b-button
          v-if="$can('update', 'Job')"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="ml-2 mr-2 mt-1"
          variant="outline-warning"
          @click="
            $router.push({
              name: 'job-edit',
              params: $route.params,
            })
          "
        >
          <feather-icon
            icon="EditIcon"
            class="mr-50 mt-0 cursor-pointer text-warning"
          />
          Edit Job
        </b-button>
        <b-button
          v-if="$can('read', 'Job')"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="ml-2 mr-2 mt-1"
          variant="outline-primary"
          @click="
            $router.push({
              name: 'job-details',
              params: $route.params,
            })
          "
        >
          <feather-icon
            icon="EyeIcon"
            class="mr-50 mt-0 cursor-pointer text-primary"
          />
          View Job Details
        </b-button>
        <b-button
          v-if="$can('read', 'Job')"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="ml-2 mr-2 mt-1"
          variant="primary"
          @click="
            $router.push({
              name: 'job-shortlist',
              params: $route.params,
            })
          "
        >
          <feather-icon
            icon="FeatherIcon"
            class="mr-50 mt-0 cursor-pointer"
          />
          Shortlist Applicants
        </b-button>
      </b-card-body>
    </b-card>

    <b-tabs
      v-if="job"
      horizontal
      content-class="col-12 col-md-12 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-12 col-12"
      nav-class="nav-left"
    >
      <b-tab
        active
        lazy
      >
        <template #title>
          <feather-icon
            icon="ListIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Submissions</span>
        </template>

        <job-submissions
          :job-i-d="jobID"
          :job="job"
          :find-department="findDepartment"
          :show-toast="showToast"
        />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon
            icon="CheckIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Shortlisted</span>
        </template>

        <job-shortlist
          :job-i-d="jobID"
          :job="job"
          :find-department="findDepartment"
          :show-toast="showToast"
        />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon
            icon="AwardIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Awarded</span>
        </template>

        <b-alert
          variant="primary"
          show
        >
          <div class="alert-body">
            <span><strong>Hey There!</strong> Please check back later to check who
              was awarded this position.</span>
          </div>
        </b-alert>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  BCard,
  BCardBody,
  BTabs,
  BTab,
  VBPopover,
  BButton,
  BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { formatDate } from '@core/utils/filter'
import { mapGetters } from 'vuex'
import JobHeader from './components/JobHeader.vue'
import JobSubmissions from './components/JobSubmissions.vue'
import JobShortlist from './components/JobShortlist.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BTabs,
    BTab,
    BButton,
    BAlert,
    JobHeader,
    JobSubmissions,
    JobShortlist,
  },
  directives: {
    Ripple,
    'b-popover': VBPopover,
  },
  data() {
    return {
      jobID: this.$route.params.id,
      jobFetchFailed: false,
    }
  },
  computed: {
    ...mapGetters('jobs', {
      jobs: 'getJobs',
    }),
    jobOpen() {
      return moment(new Date()).isSameOrAfter(moment(this.job.opening_date))
    },
    jobClosed() {
      return moment(this.job.closing_date).isSameOrBefore(moment(new Date()))
    },
    job() {
      if (this.jobID) {
        if (this.jobs.length === 0 && !this.jobFetchFailed) {
          // eslint-disable-next-line  vue/no-async-in-computed-properties
          this.$store
            .dispatch('jobs/fetchJob', { id: this.jobID })
            .catch(err => {
              this.jobFetchFailed = true
              this.showToast(
                'Error',
                `Job details cannot be found at the moment. Please try again later. Error: ${err.message}`,
                'X',
                'danger',
                // eslint-disable-next-line comma-dangle
                15000,
              )
            })
        }
        if (this.jobFetchFailed) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.$router.push({ name: 'jobs' })
        }
        const details = this.jobs.find(e => e.id === this.jobID)
        if (!details) {
          this.showToast(
            'Error',
            'Job details cannot be found at the moment. Please try again later.',
            'X',
            'danger',
            // eslint-disable-next-line comma-dangle
            15000,
          )
        }
        return details
      }
      return {}
    },
    departments() {
      return this.$store.state.departments.departments || []
    },
  },
  methods: {
    formatDate,
    timeRemaining(d) {
      const date = moment(d)
      return -moment().diff(date, 'days')
    },
    jobRemaining() {
      return moment(new Date()).unix() >= moment(this.job.opening_date)
    },
    jobPassed() {
      return (
        (this.jobRemaining
          ? this.timeRemaining(this.job.closing_date)
          : this.timeRemaining(this.job.opening_date)) < 0
      )
    },
    showToast(title, text, icon, variant, timeout = 10000) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            text,
            icon: `${icon}Icon`,
            variant,
          },
        },
        {
          position: 'bottom-right',
          timeout,
          // eslint-disable-next-line comma-dangle
        },
      )
    },
    findDepartment(id) {
      const result = this.departments.find(e => e.id === id)
      if (result) {
        return result.name
      }
      return this.$store.dispatch('departments/fetchDepartments').then(() => {
        const resultDB = this.departments.find(e => e.id === id)
        return resultDB ? resultDB.name : 'Department not available'
      })
    },
  },
}
</script>

<style></style>
