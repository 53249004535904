<template>
  <b-card-header class="pb-1">
    <b-alert
      v-if="!profileValid"
      variant="warning"
      show
    >
      <div class="alert-body">
        <span><b-link
          class="text-warning"
          :to="{ name: 'update-profile' }"
        ><strong>Update your profile</strong></b-link>
          to enable you to apply for this job. We need to check if you qualify
          for this post.
          {{ profileError }}</span>
      </div>
    </b-alert>
    <b-media no-body>
      <b-media-body>
        <b-row>
          <b-col cols="1">
            <feather-icon
              size="30"
              icon="CornerUpLeftIcon"
              class="mr-50 mt-0 cursor-pointer text-primary"
              @click="$router.push({ name: 'jobs' })"
            />
          </b-col>
          <b-col cols="11">
            <h4 class="mb-0 ml-0">
              <b-badge
                pill
                :variant="`light-primary`"
              >
                {{ job.number }}
              </b-badge>
            </h4>
            <h4 class="mb-0">
              {{ job.post }}
              <b-badge
                pill
                :variant="`light-secondary`"
                class="text-capitalize"
              >
                {{ job.positions }}
                <small>Position{{ job.positions === 1 ? '' : 's' }}</small>
              </b-badge>
            </h4>

            <small
              v-b-popover.hover.bottom="
                `Job opened on ${formatDate(job.opening_date, {
                  month: 'short',
                  day: 'numeric',
                  weekday: 'short',
                  year: 'numeric',
                })} closing on ${formatDate(job.closing_date, {
                  month: 'short',
                  day: 'numeric',
                  weekday: 'short',
                  year: 'numeric',
                })}`
              "
              title="Details"
              class="text-muted"
            ><strong>
              {{
                jobRemaining
                  ? jobClosed
                    ? -timeRemaining(job.closing_date)
                    : timeRemaining(job.closing_date)
                  : timeRemaining(job.opening_date)
              }}
              day{{
                (jobRemaining
                  ? timeRemaining(job.closing_date)
                  : timeRemaining(job.opening_date)) === 1
                  ? ''
                  : 's'
              }}
              {{ jobClosed ? 'elapsed' : 'remaining' }}</strong></small>
          </b-col>
        </b-row>
      </b-media-body>
    </b-media>
    <b-badge
      pill
      variant="light-primary"
    >
      {{ department(job.department) }}
    </b-badge>
  </b-card-header>
</template>

<script>
import {
  BCardHeader,
  BMedia,
  BMediaBody,
  BBadge,
  VBPopover,
  BRow,
  BCol,
  BAlert,
  BLink,
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BCardHeader,
    BMedia,
    BMediaBody,
    BBadge,
    BRow,
    BCol,
    BAlert,
    BLink,
  },

  directives: {
    'b-popover': VBPopover,
  },
  props: {
    profileError: {
      type: String,
      required: true,
    },
    profileValid: {
      type: Boolean,
      required: true,
    },
    job: {
      type: Object,
      required: true,
    },
    jobRemaining: {
      required: true,
    },
    jobClosed: {
      required: true,
    },
    timeRemaining: {
      required: true,
    },
  },

  computed: {
    departments() {
      return this.$store.state.departments.departments || []
    },
  },

  methods: {
    formatDate,
    department(id) {
      const department = this.departments.find(e => e.id === id)
      return department ? department.name : 'Unknown Department'
    },
  },
}
</script>

<style></style>
