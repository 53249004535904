<template>
  <b-card
    no-body
    title="Submissions"
  >
    <b-card-body>
      <b-row>
        <b-col>
          <b-dropdown
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            text="Export to"
            variant="outline-primary"
            class="mr-2 mb-1"
          >
            <b-dropdown-item @click.prevent="exportPDF">
              <feather-icon
                icon="DownloadIcon"
                size="16"
                class="align-middle mr-1"
              />
              PDF
            </b-dropdown-item>
            <b-dropdown-item>
              <download-csv
                :data="rows"
                :name="`${job.number}_${job.post.replace(' ', '_')}.csv`"
              >
                <feather-icon
                  icon="DownloadIcon"
                  size="16"
                  class="align-middle mr-1"
                />
                CSV
              </download-csv>
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item disabled>
              <feather-icon
                icon="MailIcon"
                size="16"
                class="align-middle mr-1"
              />Email</b-dropdown-item>
          </b-dropdown>
          <b-button
            v-if="$can('read', 'Job')"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="mr-2 mb-1"
            variant="primary"
            :disabled="fetching"
            @click="refreshSubmissions"
          >
            <feather-icon
              v-if="!fetching"
              icon="RefreshCwIcon"
              class="mr-50 mt-0 cursor-pointer"
            />
            <b-spinner
              v-if="fetching"
              small
              type="grow"
            />
            <span>Refresh</span>
          </b-button>
        </b-col>
        <b-col>
          <!-- search input -->
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <!-- table -->
      <vue-good-table
        v-if="!fetching"
        :key="rows.length"
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Name -->
          <span
            v-if="props.column.field === 'uid'"
            class="text-nowrap"
          >
            <b-avatar
              :src="props.row.picture"
              :text="avatarText(`${props.row.uid}`)"
              :variant="`light-primary`"
            />
            <span class="text-nowrap ml-1">{{ props.row.uid }}</span>
          </span>

          <span v-else-if="props.column.field === 'createdAt'">
            <span class="text-nowrap">{{ props.row.createdAt }}</span>
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge
              pill
              :variant="getStatusColor(props.row.status)"
            >
              <span class="text-nowrap">{{ props.row.status }}</span>
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item>
                  <feather-icon
                    icon="EyeIcon"
                    class="mr-50"
                  />
                  <span>More Details</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['5', '10', '20']"
                class="mx-1"
                @input="
                  value => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="value => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-body>
  </b-card>
</template>

<script>
/* eslint-disable global-require */

import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  VBPopover,
  BCol,
  BRow,
  BCardBody,
  BDropdownDivider,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import JsPDF from 'jspdf'
import DownloadCsv from 'vue-json-csv'
import { mapState } from 'vuex'
import store from '@/store/index'
import { formatDate, avatarText } from '@core/utils/filter'
import moment from 'moment'

export default {
  components: {
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCol,
    BRow,
    BCardBody,
    BDropdownDivider,
    BButton,
    BSpinner,
    DownloadCsv,
  },
  directives: {
    Ripple,
    'b-popover': VBPopover,
  },
  props: {
    jobID: {
      type: String,
      required: true,
    },
    job: {
      type: Object,
      required: true,
    },
    findDepartment: {
      required: true,
    },
    showToast: {
      required: true,
    },
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      columns: [
        {
          label: 'User',
          field: 'uid',
        },
        {
          label: 'National ID',
          field: 'nationalID',
        },
        {
          label: 'Phone Number',
          field: 'phone',
        },
        {
          label: 'Ward',
          field: 'ward',
        },
        {
          label: 'Sub County',
          field: 'sub_county',
        },
        {
          label: 'County',
          field: 'county',
        },
        {
          label: 'Disabled',
          field: 'disabled',
        },
        {
          label: 'Submitted On',
          field: 'createdAt',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      fetching: false,
      rows: [],
    }
  },
  computed: {
    ...mapState('jobs', ['jobs', 'jobsSubmissions']),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    jobsSubmissions() {
      this.generateRows()
    },
  },
  created() {
    // TODO:
    if (this.$route.params.id) {
      store
        .dispatch('jobs/fetchJobSubmissions', {
          id: this.$route.params.id,
        })
        .then(() => {
          this.generateRows()
        })
    }
  },
  methods: {
    avatarText,
    formatDate,
    generateRows() {
      const { jobsSubmissions } = this
      if (this.jobID) {
        if (`${this.jobID}` in jobsSubmissions) {
          this.rows = jobsSubmissions[this.jobID].map((row, index) => {
            const status = this.getStatus(row)
            // TODO: Add county, ward and subcounty
            return {
              no: `${parseInt(index, 10) + 1}`,
              uid: `${row.uid.given_name}${
                row.uid.middle_name ? ` ${row.uid.middle_name} ` : ' '
              }${row.uid.family_name}`,
              nationalID: row.snapshot.information.nationalID,
              phone: row.uid.phone_number,
              status: status.status,
              color: status.color,
              ward: row.snapshot.information.ward,
              sub_county: row.snapshot.information.sub_county,
              county: row.snapshot.information.county,
              disabled: row.snapshot.information.disabled ? 'YES' : 'NO',
              createdAt: this.formatDate(row.createdAt, {
                month: 'short',
                day: 'numeric',
                weekday: 'short',
                year: 'numeric',
              }),
            }
          })
        }
      } else {
        this.showToast(
          'Error',
          'Something went wrong. Please try again later.',
          'Bell',
          'warning',
        )
        this.rows = []
      }
    },
    async refreshSubmissions() {
      this.fetching = true
      this.$forceUpdate()
      await this.$store
        .dispatch('jobs/fetchJobSubmissions', {
          id: this.$route.params.id,
        })
        .then(() => {
          this.generateRows()
        })
      this.$forceUpdate()
      setTimeout(() => {
        this.fetching = false
      }, 1500)
    },
    getStatusColor(status) {
      let color = 'secondary'
      switch (status) {
        case 'Awarded Job':
          color = 'success'
          break
        case 'Interview Passed':
          color = 'info'
          break
        case 'Interview Failed':
          color = 'danger'
          break
        case 'Shortlisted':
          color = 'info'
          break
        case 'Rejected':
          color = 'danger'
          break
        case 'Processed':
          color = 'warning'
          break
        case 'Pending':
          color = 'warning'
          break
        default:
          color = 'danger'
          break
      }
      return color
    },
    getStatus(application) {
      const status = {
        status: 'Pending',
        color: 'secondary',
      }
      if (application.awardedJob) {
        status.status = 'Awarded Job'
        status.color = 'success'
      } else if (application.interviewed) {
        status.status = application.interviewResult
          ? 'Interview Passed'
          : 'Interview Failed'
        status.color = application.interviewResult ? 'info' : 'danger'
      } else if (application.shortListed) {
        status.status = 'Shortlisted'
        status.color = 'info'
      } else if (application.rejected) {
        status.status = 'Rejected'
        status.color = 'danger'
      } else if (application.processed) {
        status.status = 'Processed'
        status.color = 'warning'
      }
      return status
    },
    exportPDF() {
      const headerName = {
        no: { name: 'NO.', width: 18, align: 'center' },
        uid: { name: 'Applicant Name', width: 80, align: 'left' },
        nationalID: { name: 'ID Number', width: 40, align: 'center' },
        phone: { name: 'Phone Number', width: 40, align: 'center' },
        ward: { name: 'Ward', width: 30, align: 'center' },
        sub_county: { name: 'Sub County', width: 40, align: 'center' },
        county: { name: 'County', width: 40, align: 'center' },
        status: { name: 'Status', width: 35, align: 'center' },
        createdAt: { name: 'Date Submitted', width: 50, align: 'center' },
      }

      const createHeaders = keys => {
        const result = []
        for (let i = 0; i < keys.length; i += 1) {
          result.push({
            id: keys[i],
            name: [keys[i]],
            prompt: headerName[keys[i]].name,
            width: headerName[keys[i]].width,
            align: 'center',
            padding: 0,
          })
        }
        return result
      }

      const headers = createHeaders([
        'no',
        'uid',
        'nationalID',
        'phone',
        'ward',
        'sub_county',
        'county',
        'status',
        'createdAt',
      ])

      const doc = new JsPDF('landscape')
      doc.addImage(
        require('@/assets/images/flags/kcg-logo.png'),
        'PNG',
        138.5,
        10,
        20,
        20,
        'center',
      )
      doc.setFont('times', 'bold')
      doc.text(
        'THE COUNTY GOVERNMENT OF KERICHO',
        148.5,
        40,
        null,
        null,
        'center',
      )
      doc.setFontSize(16)
      doc.text('PUBLIC SERVICE BOARD', 148.5, 48, null, null, 'center')
      doc.line(0, 53, 297, 53)
      doc.setFontSize(12)
      doc.text(
        `${this.findDepartment(this.job.department).toUpperCase()}`,
        148.5,
        60,
        null,
        null,
        'center',
      )
      doc.text(`${this.job.post}`, 148.5, 70, null, null, 'center')
      doc.text(
        `${
          this.job.job_group !== 'N/A'
            ? `JOB GROUP ${this.job.job_group} - `
            : ''
        }${this.job.positions} position${this.job.position === 1 ? '' : 's'}`,
        148.5,
        75,
        null,
        null,
        'center',
      )
      doc.text('ALL APPLICANTS', 148.5, 90, null, null, 'center')
      doc.setFontSize(8)
      doc.table(10, 95, this.rows, headers, { autoSize: false })
      doc.save(
        `${this.job.number}_${this.job.post.replace(' ', '_')}_${moment(
          new Date(),
        ).unix()}.pdf`,
      )
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
